/**
 * 发送异步请求函数模块
 * 封装axios
 * 函数的返回值为Promise对象
 * 统一处理请求异常
 */
import axios from 'axios'
import { showToast } from 'vant'
import qs from 'qs'

const baseUrl = "https://api.bank.teach01.com"
// const baseUrl = "http://localhost:8300"

export const ajax = (url, data = {}, method = 'GET') => {
  return new Promise((resolve, reject) => {
    let promise
    if (method === 'GET') {
      url += "?" + qs.stringify(data)
      promise = axios.get(baseUrl + url)
    } else if (method === 'POST') {
      promise = axios.post(baseUrl + url, data)
    }
    promise.then((res) => {
      const code = res.data.code
      if (code === 0) {
        resolve(res.data)
      } else {
        showToast(res?.data?.message || res?.data?.msg || "请求失败")
      }
    }).catch((err) => {
      showToast(err?.data?.message || err?.data?.msg || "请求失败")
    })
  })
}

export const ajaxGet = (url, data = {}) => {
  data = {
    ...data,
    orgId: sessionStorage.getItem("orgId") || "",
    userId: sessionStorage.getItem("userId") || ""
  }
  return ajax(url, data, 'GET')
}

export const ajaxPost = (url, data = {}) => {
  data = {
    ...data,
    orgId: sessionStorage.getItem("orgId") || "",
    userId: sessionStorage.getItem("userId") || ""
  }
  return ajax(url, data, 'POST')
}

export const ajaxUploadOss = (url, data = {}) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData()
    Object.keys(data).forEach(key => {
      formData.append(key, data[key])
    })
    axios.post(url, formData, {
      header: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(response => {
      if (response.status >= 200 && response.status < 300) {
        resolve(response)
      } else {
        reject(response)
      }
    }).catch(err => {
      reject(err)
    })
  })
}