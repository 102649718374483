/**
 * author:LvGuowei
 *
 * Function features：
 * 发送异步请求函数模块
 * 封装axios
 * 处理函数的返回值Promise对象
 * 统一处理请求异常
 */
import { ajaxGet, ajaxPost, ajaxUploadOss } from './ajax';
const wxLogin = () =>
  ajaxGet(
    'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxee88b7781ddb5ff8&redirect_uri=http%3A%2F%2Fbank.huihoukj.cn%2FwebApi.php%3Ft%3DweChat_auth&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect',
    '',
    'GET'
  );
// "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx48fabf2c53e9e79d&redirect_uri=http%3A%2F%2F127.0.0.1%3A8080%2F%23%2F&response_type=code&scope=snsapi_base#wechat_redirect"

// 根据code获取openId
const getOpenIdByCode = (params = {}) =>
  ajaxGet('/bank-app-api/account/getOpenid', params);

// 根据code获取用户微信信息和openId  需要用户授权
const getUserWXInfoByCode = (params = {}) =>
  ajaxGet('/bank-app-api/account/getBaseUserInfo', params);

// 发送验证码
const sendCode = (params = {}) =>
  ajaxGet('/bank-app-api/account/sendSmsCode', params);

// 注册&登录
const login = (params = {}) => ajaxPost('/bank-app-api/account/signIn', params);

// 根据userId获取用户信息
const getUserInfo = (params = {}) =>
  ajaxGet('/bank-app-api/account/getById', params);

// 获取银行列表
const getBankList = (params = {}) =>
  ajaxGet('/bank-app-api/bank/getSelectBank', params);

// 获取信用卡列表
const getBankCardList = (params = {}) =>
  ajaxPost('/bank-app-api/creditCard/selectPage', params);

// 获取轮播图
const getBannerList = () => ajaxGet('/bank-app-api/post/getBannerList');

// 获取信用卡详情
const getBankCardInfo = (params = {}) =>
  ajaxGet('/bank-app-api/creditCard/getDetailById', params);

// 获取信用卡海报列表
const getCardPosterList = () => ajaxGet('/bank-app-api/post/getCardPostList');

// 获取邀请海报列表
const getInvitePosterList = () => ajaxGet('/bank-app-api/post/getInviteList');

// 生成信用卡用户二维码
const generatorCardQrCode = (params = {}) =>
  ajaxPost('/bank-app-api/post/generatorCardQrCode', params);

// 绑定支付宝
const bindAliPay = (params = {}) =>
  ajaxPost('/bank-app-api/account/bindAliPay', params);

// 获取联系人
const getContactPerson = (params = {}) =>
  ajaxGet('/bank-app-api/account/getContactPerson', params);

// 获取圈子列表
const getCircleList = (params = {}) =>
  ajaxPost('/bank-app-api/circle/getCircleList', params);

// 获取帮助中心列表
const getHelpCenterList = (params = {}) =>
  ajaxPost('/bank-app-api/helpCenter/getHelpCenterList', params);

//根据信用卡id获取信用卡海报
const getPostByCardId = (params = {}) =>
  ajaxGet('/bank-app-api/post/getPostByCardId', params);

// 更新用户信息
const updateAccountInfo = (params = {}) =>
  ajaxPost('/bank-app-api/account/updateAccountInfo', params);

// 获取上传签名
const getUploadSign = (params = {}) =>
  ajaxPost('/bank-app-api/common/getSign', params);

// 上传文件oss
const uploadFileOSS = (params = {}) =>
  ajaxUploadOss('https://xyk-api.oss-cn-beijing.aliyuncs.com', params);

// 信用卡申请
const applyCard = (params = {}) =>
  ajaxPost('/bank-app-api/creditCard/getExternalAPIInterface', params);

// 分页查询信用卡订单信息接口
const getOrderList = (params = {}) => ajaxPost('/bank-app-api/creditCard/selectCreditCardOrderPage', params)

// 计算可提现与总收入金额
const getTotalWithdrawableIncome = (params = {}) => ajaxGet('/bank-app-api/account/getTotalWithdrawableIncome', params)

// 我的团队
const getMyTeam = (params = {}) => ajaxGet('/bank-app-api/account/getMyTeam', params)

// 获取用户收益明细
const getMoneyDetail = (params = {}) => ajaxGet('/bank-app-api/account/getMoneyDetail', params)

// 用户申请提现金额
const applyAmount = (params = {}) => ajaxPost('/bank-app-api/account/applyAmount', params)

// 获取银行状态
const getBankStatus = (params = {}) => ajaxGet('/bank-app-api/systemStatus/getBankSystemStatus', params)


const getWxSDKConfig = (params) => ajaxPost('/bank-app-api/common/getWxSDKConfig',params)
export default {
  wxLogin,
  getOpenIdByCode,
  getUserWXInfoByCode,
  sendCode,
  login,
  getUserInfo,
  getBankList,
  getBankCardList,
  getBannerList,
  getBankCardInfo,
  getCardPosterList,
  getInvitePosterList,
  generatorCardQrCode,
  bindAliPay,
  getContactPerson,
  getCircleList,
  getHelpCenterList,
  getPostByCardId,
  updateAccountInfo,
  getUploadSign,
  uploadFileOSS,
  applyCard,
  getOrderList,
  getTotalWithdrawableIncome,
  getMyTeam,
  getMoneyDetail,
  applyAmount,
  getBankStatus,
  getWxSDKConfig
};
