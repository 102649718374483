<script setup>
import { ref, defineProps } from 'vue'
const props = defineProps({
  visible: Boolean,
  onOk: Function,
})
</script>

<template>
  <van-popup v-model:show="props.visible" :style="{ padding: '20px' }" round>
    <div class="protocol">
      <div class="protocol-title">海豚聚合推广公约</div>
      <div class="protocol-list">
        <span>1、不得<span style="color: red;">承诺</span>客户一定下卡以及<span style="color: red;">承诺</span>额度</span>
        <span>2、不得<span style="color: red;">冒用银行的名义</span>进行信用卡推广</span>
        <span>3、不得以任何理由向客户<span style="color: red;">索取</span>任何形式的<span style="color: red;">费用</span>。</span>
        <span>4、<span style="color: red;">不得以办卡送POS机</span>等诱导客户申卡。</span>
        <span>5、不得代客户填写资料提交信用卡申请或冒用他人名义申请信用卡。</span>
        <span>6、不得引导客户只赚取佣金不激活使用和如有违反引发投诉，平台有权采取包括但不限，于扣除佣金、冻结账户、报警以及追诉赔偿等处罚措施。</span>
        <span>7、申卡页面所有信息必须前后一致，否则会导致无法结算。</span>
      </div>
      <van-button class="protocol-btn" @click="props.onOk">同意</van-button>
    </div>
  </van-popup>
</template>

<style lang="less" scoped>
@import url(./index.less);
</style>