<script setup>
import { ref, onMounted } from 'vue';
import router from '@/router';

const active = ref(0);

// onMounted(() => {
//   // 判断登录状态
//   if (!sessionStorage.getItem("userId")) {
//     if (!sessionStorage.getItem("enterPage")) {
//       if (window.history.state.current) {
//         if (window.history.state.current === "/") {
//           sessionStorage.setItem("enterPage", "/home")
//         } else {
//           sessionStorage.setItem("enterPage", window.history.state.current)
//         }
//       } else {
//         sessionStorage.setItem("enterPage", "/home")
//       }
//     }
//     router.replace({
//       path: "/",
//     })
//   }
// })

</script>

<template>
  <router-view />
  <van-tabbar v-model="active" active-color="#ee0a24" v-if="$route.meta.showTabBar" route>
    <van-tabbar-item replace to="/home">
      <span>首页</span>
      <template #icon="props">
        <van-icon v-if="props.active" name="wap-home" />
        <van-icon v-else name="wap-home-o" />
      </template>
    </van-tabbar-item>
    <van-tabbar-item replace to="/myself">
      <span>我的</span>
      <template #icon="props">
        <van-icon v-if="props.active" name="manager" />
        <van-icon v-else name="manager-o" />
      </template>
    </van-tabbar-item>
  </van-tabbar>
</template>

