import api from '@/api/api';

export default async function init(){
  let href = window.location.href;
  let { data } = await api.getWxSDKConfig({url:href});
  console.log(data,href);
  // alert(href.split('#')[0])
  // alert(JSON.stringify(data))
  wx.config({
    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
    appId: data.appId, // 必填，公众号的唯一标识
    timestamp: data.timestamp, // 必填，生成签名的时间戳
    nonceStr: data.nonceStr, // 必填，生成签名的随机串
    signature: data.signature,// 必填，签名
    jsApiList: ['getLocation'] // 必填，需要使用的JS接口列表
  });
}