import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import animate from 'animate.css'
import api from './api/api'
// 1. 引入你需要的组件
import {
  Button,
  NavBar,
  Swipe,
  SwipeItem,
  Form,
  Field,
  Cell,
  CellGroup,
  Empty,
  Watermark,
  Tag,
  Grid,
  GridItem,
  Image as VanImage,
  Toast,
  RollingText,
  Rate,
  Tabbar,
  TabbarItem,
  Tab,
  Tabs,
  Search,
  Popup,
  Checkbox,
  CheckboxGroup,
  Collapse,
  CollapseItem,
  Step,
  Steps,
  Icon,
  DatePicker,
  Dialog,
  Uploader,
  ImagePreview,
  Loading
} from 'vant';

// 2. 引入组件样式
import 'vant/lib/index.css'

const app = createApp(App)
app.config.globalProperties.$api = api;
app.use(ImagePreview).use(Uploader).use(Dialog).use(DatePicker).use(Icon).use(Checkbox).use(CheckboxGroup).use(Collapse).use(CollapseItem).use(Step).use(Steps);
app.use(Loading).use(animate).use(store).use(router).use(Popup).use(Button).use(NavBar).use(Swipe).use(SwipeItem).use(Form).use(Field).use(Cell).use(CellGroup).use(Empty).use(Tag).use(Watermark).use(Grid).use(GridItem).use(VanImage).use(Toast).use(RollingText).use(Rate).use(Tabbar).use(TabbarItem).use(Tab).use(Tabs).use(Search).mount('#app')
