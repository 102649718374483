import router from '@/router';
import qs from 'qs';
import { showToast } from 'vant';
import API from './api/api'


// 检测环境
export const isWxEnv = () => {
  let ua = window.navigator.userAgent.toLowerCase();
  //通过正则表达式匹配ua中是否含有MicroMessenger字符串
  return ua.match(/MicroMessenger/i) == 'micromessenger'
}

// 检测机型
export const isPhone = () => {
  const system = {}
  const p = navigator.platform
  const u = navigator.userAgent
  system.win = p.indexOf('Win') === 0
  system.mac = p.indexOf('Mac') === 0
  system.x11 = p === 'X11' || p.indexOf('Linux') === 0
  if (system.win || system.mac || system.xll) {
    if (u.indexOf('Windows Phone') > -1) {
      // 手机端
    } else {
      /*this.$router.push({
        name: 'ErrorView',
        params: { pathMatch: this.$route.path.substring(1).split('/') }
      })*/
    }
  }
}

// 微信登录
export function wxLogin() {
  const appid = 'wxee88b7781ddb5ff8';
  const redirect_uri = encodeURIComponent(location.href);
  return new Promise((resolve, reject) => {
    // 检查链接是否存在code
    const search = qs.parse(location.search, { ignoreQueryPrefix: true });
    const { code } = search;
    if (sessionStorage.getItem('userId')) {
      // 已登录
      resolve()
    } else {
      // 未登录
      if (code) {
        // 屏蔽code
        history.replaceState({}, '',  location.origin + '/' + location.hash);
        // 微信登录back回来，code换openId
        API.getOpenIdByCode({
          code
        }).then(res => {
          const { openid, userId } = res.data
          sessionStorage.setItem("openId", openid)
          if (userId) {
            // 老用户
            sessionStorage.setItem("userId", userId)
            // 获取用户信息
            API.getUserInfo({
              id: userId
            }).then(response => {
              if (response.data) {
                const { institutionId, accountId, accountStatus, accountAvatarurl, accountNickname, accountPhone, accountName, accountIdcard, accountInvitecode, accountEwcode, accountZfbname, accountZfbnumber, accountInviteUser } = response.data
                sessionStorage.setItem("orgId", institutionId || "")
                sessionStorage.setItem("userId", accountId || "")
                sessionStorage.setItem("avatar", accountAvatarurl || "")
                sessionStorage.setItem("userNickName", accountNickname || "")
                sessionStorage.setItem("phone", accountPhone || "")
                sessionStorage.setItem("userName", accountName || "")
                sessionStorage.setItem("idCard", accountIdcard || "")
                sessionStorage.setItem("inviteCode", accountInvitecode || "")
                sessionStorage.setItem("qrCode", accountEwcode || "")
                sessionStorage.setItem("zfbName", accountZfbname || "")
                sessionStorage.setItem("zfbAccount", accountZfbnumber || "")
                sessionStorage.setItem("accountStatus", accountStatus || "")
                sessionStorage.setItem("accountInviteUser", accountInviteUser || "")
                resolve()
              } else {
                reject()
              }
            }).catch(err => {
              reject(err)
            })
          } else {
            // 新用户
            // 申卡页面特殊处理
            if (location.hash.includes("apply")) {
              resolve()
            } else {
              reject()
            }
          }
        }).catch(err => {
          reject(err)
        })
      } else {
        // 第一次进来 微信授权登录
        location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_base#wechat_redirect`;
      }
    }
  })

}

