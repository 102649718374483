import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'main',
    component: () => import('../views/MainView/MainView.vue'),
    meta: {
      showTabBar: false
    }
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/HomeView/HomeView.vue'),
    meta: {
      showTabBar: true
    }
  },
  {
    path: '/myself',
    name: 'MyselfView',
    component: () => import('../views/MyselfView/MyselfView.vue'),
    meta: {
      showTabBar: true
    }
  },
  {
    path: '/order',
    name: 'OrderView',
    component: () => import('../views/OrderView/OrderView.vue'),
    meta: {
      showTabBar: false
    }
  },
  {
    path: '/error',
    name: 'ErrorView',
    component: () => import('../views/ErrorView/ErrorView.vue'),
    meta: {
      showTabBar: false
    }
  },
  {
    path: '/detail',
    name: 'DetailView',
    component: () => import('../views/DetailView/DetailView.vue'),
    meta: {
      showTabBar: false
    }
  },
  {
    path: '/apply',
    name: 'ApplyCard',
    component: () => import('../views/ApplyCard/index.vue'),
    meta: {
      showTabBar: false
    }
  },
  {
    path: '/post',
    name: 'PostView',
    component: () => import('../views/PostView/PostView.vue'),
    meta: {
      showTabBar: false
    }
  },
  {
    path: '/help',
    name: 'HelpView',
    component: () => import('../views/HelpView/HelpView.vue'),
    meta: {
      showTabBar: false
    }
  },
  {
    path: '/userInfo',
    name: 'UserInfo',
    component: () => import('../views/UserInfo/index.vue'),
    meta: {
      showTabBar: false
    }
  },
  {
    path: '/bindAliPay',
    name: 'BindAliPay',
    component: () => import('../views/BindAliPay/index.vue'),
    meta: {
      showTabBar: false
    }
  },
  {
    path: '/income',
    name: 'Income',
    component: () => import('../views/Income/index.vue'),
    meta: {
      showTabBar: false
    }
  },
  {
    path: '/moreInvitePoster',
    name: 'MoreInvitePoster',
    component: () => import('../views/MoreInvitePoster'),
    meta: {
      showTabBar: false
    }
  },
  {
    path: '/moreCardPoster',
    name: 'MoreCardPoster',
    component: () => import('../views/MoreCardPoster'),
    meta: {
      showTabBar: false
    }
  },
  {
    path: '/inviteMan',
    name: 'InviteMan',
    component: () => import('../views/InviteMan'),
    meta: {
      showTabBar: false
    }
  },
  {
    path: '/myteam',
    name: 'MyTeam',
    component: () => import('../views/MyTeam'),
    meta: {
      showTabBar: false
    }
  },
  {
    path: '/moments',
    name: 'Moments',
    component: () => import('../views/Moments'),
    meta: {
      showTabBar: false
    }
  },
  {
    path: '/share',
    name: 'Share',
    component: () => import('../views/Share'),
    meta: {
      showTabBar: false
    }
  },
  {
    path: '/shareInvite',
    name: 'ShareInvite',
    component: () => import('../views/ShareInvite'),
    meta: {
      showTabBar: false
    }
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
