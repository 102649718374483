import { createStore } from 'vuex'

export default createStore({
  state: {
    show: true
  },
  getters: {
  },
  mutations: {
    setTabShow: function (state, value) {
      state.show = value
    }
  },
  actions: {
  },
  modules: {
  }
})
