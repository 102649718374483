
<script setup>
import IndexView from './views/IndexView/IndexView'
import { onMounted, ref, getCurrentInstance } from 'vue'
import { isWxEnv, wxLogin } from './common'
import { showToast } from 'vant';
import ProtocolDialog from './components/ProtocolDialog'
import init from '@/utils/initSdk';

const { proxy } = getCurrentInstance();
const show = ref(false)
const showLogin = ref(false)
const name = ref('')  // 姓名
const idCard = ref('')  // 身份证号
const mobile = ref('')  // 手机号
const mobileCode = ref('') // 验证码
const code = ref('') // 邀请码
const codeTime = ref("发送") // 验证发倒计时
const timer = ref(null)
const checked = ref(true);
const potocolVisible = ref(false) // 协议弹窗

onMounted(() => {
  // this.show = this.$store.state.show
  if (isWxEnv()) {
    init().then(()=>{
      wxLogin().then(() => {
        show.value = true
      }).catch(() => {
        showLogin.value = true
      })
    })
   
  } else {
    if (sessionStorage.getItem("userId")) {
      show.value = true
    } else {
      showLogin.value = true
    }
  }
})

//发送验证码
const sendCode = () => {
  codeTime.value = 59
  timer.value = setInterval(() => {
    if (codeTime.value === 1) {
      clearInterval(timer.value)
      timer.value = null
      codeTime.value = "发送"
      return
    }
    codeTime.value -= 1
  }, 1000)
  proxy.$api.sendCode({
    phone: mobile.value,
  }).then(res => {
    showToast("发送成功")
  })
}

// 登录or注册
const register = () => {
  if (!checked.value) {
    showToast("请勾选同意协议")
    return
  }
  proxy.$api.login({
    name: name.value,
    idCard: idCard.value,
    accountPhone: mobile.value,
    inviteCode: code.value,
    smsCode: mobileCode.value,
    openId: sessionStorage.getItem("openId")
  }).then(response => {
    // const response = { "accountId": 3, "accountNickname": "用户b243e8", "accountAvatarUrl": null, "accountPhone": "19270569856", "accountName": null, "accountIdcard": null, "accountInvitecode": null, "accountEwcode": null, "accountZfbname": null, "accountZfbnumber": null, "accountStatus": null, "institutionId": 1, "isDelete": null, "createTime": "1692274734897", "updateTime": "1692274734897" }
    if (response.data) {
      const { institutionId, accountAvatarurl, accountNickname, accountPhone, accountName, accountIdcard, accountInvitecode, accountEwcode, accountZfbname, accountZfbnumber, accountId, accountInviteUser, accountStatus, } = response.data
      sessionStorage.setItem("orgId", institutionId || "")
      sessionStorage.setItem("userId", accountId || "")
      sessionStorage.setItem("avatar", accountAvatarurl || "")
      sessionStorage.setItem("userNickName", accountNickname || "")
      sessionStorage.setItem("phone", accountPhone || "")
      sessionStorage.setItem("userName", accountName || "")
      sessionStorage.setItem("idCard", accountIdcard || "")
      sessionStorage.setItem("inviteCode", accountInvitecode || "")
      sessionStorage.setItem("qrCode", accountEwcode || "")
      sessionStorage.setItem("zfbName", accountZfbname || "")
      sessionStorage.setItem("zfbAccount", accountZfbnumber || "")
      sessionStorage.setItem("accountStatus", accountStatus || "")
      sessionStorage.setItem("accountInviteUser", accountInviteUser || "")

      show.value = true
      showLogin.value = false
    }
  })

};

// 打开协议弹窗
const onShowPotocolDialog = () => {
  potocolVisible.value = true
}

// 确定协议弹窗
const onOkPotocolDialog = () => {
  potocolVisible.value = false
  checked.value = true
}

</script>

<template>
  <IndexView v-if="show" />
  <!-- 如果是非微信浏览器 -->
  <div class="welcome_main" v-if="showLogin">
  </div>
  <van-popup v-model:show="showLogin" :overlay="false" :close-on-click-overlay="false" z-index="1000">
    <div style="background-color: #fff; padding: 10px">
      <van-form @submit="register">
        <van-field left-icon="contact" v-model="name" required center clearable label="姓名" placeholder="请输入中文名字"
          :rules="[{ required: true, message: '姓名不能为空' }]"></van-field>
        <van-field left-icon="idcard" v-model="idCard" required center clearable label="身份证号" placeholder="请输入身份证号"
          :rules="[{ required: true, message: '身份证号不能为空' }]"></van-field>
        <van-field left-icon="phone-o" v-model="mobile" required clearable label="手机号" placeholder="请输入手机号"
          :rules="[{ required: true, message: '手机号不能为空' }]" />
        <div class="code">
          <van-field left-icon="comment-o" v-model="mobileCode" required center clearable label="验证码"
            placeholder="请输入短信验证码" :rules="[{ required: true, message: '验证码不能为空' }]"></van-field>
          <van-button style="width: 20%" size="mini" :disabled="!(mobile && codeTime == '发送')" type="primary" round
            @click="sendCode">{{
              codeTime }}</van-button>
        </div>
        <van-field left-icon="share-o" v-model="code" required clearable label="邀请码" placeholder="请输入邀请码"
          :rules="[{ required: true, message: '邀请码不能为空' }]" />
        <van-button type="primary" style="width: 100%; margin: 20px 0px" :round="true"
          native-type="submit">注册并登录</van-button>
        <div class="login-protocol">
          <van-checkbox v-model="checked" icon-size="16px"></van-checkbox>
          <span style="margin-left: 5px">注册即表示接受</span>
          <span style="color: #1989fa; margin-left: 5px" @click="onShowPotocolDialog">《海豚聚合推广公约》</span>
        </div>
      </van-form>
    </div>
  </van-popup>
  <protocol-dialog :visible="potocolVisible" :onOk="onOkPotocolDialog" />
</template>

<style>
div {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  min-width: 300px;
}

.link_text {
  color: #1989fa;
  cursor: pointer;
}

.welcome_main {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: url("./assets/images/commonbgs.png") no-repeat;
  background-size: 100% 100%;
}

.code {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.send_button {
  width: 80%;
  position: absolute;
  bottom: 20%;
  left: 10%;
}

.login-protocol {
  display: flex;
  font-size: 12px;
  padding-bottom: 6px;
}
</style>
